import React from 'react'
import availableLangs from '@localization/availableLangs'
import TikTokRankingsPageContainer from '@containers/pagesContainers/TikTokRankingsPageContainer'
import SeoContainer from '@containers/SeoContainer'

const IndexPage = () => {
  return (
    <>
      <SeoContainer
        pageLang={availableLangs.en}
        pagePath='tiktokRankings'
        title='TikTok Rankings'
        description='Show the Top 100 TikTokers in your country or worldwide.'
      />
      <TikTokRankingsPageContainer />
    </>
  )
}

export default IndexPage
